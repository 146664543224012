import './src/styles/global.css';

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

const onRedirectCallback = appState => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => {
  // Determine the domain and clientId based on the ROBOTS_DISABLED env variable
  const isRobotsDisabled = process.env.GATSBY_ROBOTS_DISABLED === 'true';

  const domain = isRobotsDisabled 
    ? "dev-hqtd418pjqet1phs.us.auth0.com" // Replace this with your actual alternative domain
    : "dev-hqtd418pjqet1phs.us.auth0.com";

  const clientId = isRobotsDisabled 
    ? "w9gPaSyrYiH3bfJuirbmnlQVMmx7gK8d" // Replace this with your actual alternative client ID
    : "w9gPaSyrYiH3bfJuirbmnlQVMmx7gK8d";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {element}
    </Auth0Provider>
  );
};